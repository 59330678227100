import { Row } from './data-types'

export const rowData: Row[] = [
    {
        food: 'artichoke',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'asparagus',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'aubergine',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'beetroot',
        'μg/ml IgG': '3.8',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'broad bean',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'broccoli',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'brussel sprouts',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'carrots',
        'μg/ml IgG': '2.9',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'cauliflower',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'celeriac, knob celery',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'chard, beet greens',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'chickpeas',
        'μg/ml IgG': '7.0',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'chili cayenne',
        'μg/ml IgG': '6.3',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'chinese cabbage',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'courgette (zucchini)',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'cucumber',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'green bean',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'green pea',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'kale',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'kohlrabi, turnip cabbage',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'leek',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'lentil',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'mung bean, green gram',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'olive',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'onion',
        'μg/ml IgG': '2.8',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'parsnip',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'potato',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'pumpkin',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'radish red - radish white',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'red cabbage',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'rutabaga',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'savoy cabbage',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'soy bean',
        'μg/ml IgG': '74.3',
        rating: 'highly elevated',
        category: 'vegetables',
    },
    {
        food: 'spinach',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'stalk celery',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'sweet pepper',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'tomato',
        'μg/ml IgG': '5.1',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'white cabbage',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'vegetables',
    },
    {
        food: 'cane sugar',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'sweeteners',
    },
    {
        food: 'honey',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'sweeteners',
    },
    {
        food: 'anchovy',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'carp',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'cod, codling',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'crayfish',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'gilthead bream',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'haddock',
        'μg/ml IgG': '3.0',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'hake',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'halibut',
        'μg/ml IgG': '5.2',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'herring',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'mackerel',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'ocean perch',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'plaice',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'pollock',
        'μg/ml IgG': '2.7',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'salmon',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        food: 'sardine',
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
        food: 'shark',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
        food: 'sole',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
        food: 'squid, cuttlefish',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
        food: 'swordfish',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
        food: 'trout',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
        food: 'tunafish',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fish and seafood',
        food: 'zander',
    },
    {
        'μg/ml IgG': '8.2',
        rating: 'elevated',
        category: 'meat',
        food: 'beef',
    },
    {
        'μg/ml IgG': '2.8',
        rating: 'not elevated',
        category: 'meat',
        food: 'chicken',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'meat',
        food: 'deer',
    },
    {
        'μg/ml IgG': '3.7',
        rating: 'not elevated',
        category: 'meat',
        food: 'duck',
    },
    {
        'μg/ml IgG': '4.9',
        rating: 'not elevated',
        category: 'meat',
        food: 'goat meat',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'meat',
        food: 'goose',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'meat',
        food: 'hare',
    },
    {
        'μg/ml IgG': '7.6',
        rating: 'not elevated',
        category: 'meat',
        food: 'lamb',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'meat',
        food: 'ostrich meat',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'meat',
        food: 'pork',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'meat',
        food: 'quail',
    },
    {
        'μg/ml IgG': '3.5',
        rating: 'not elevated',
        category: 'meat',
        food: 'rabbit',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'meat',
        food: 'turkey hen',
    },
    {
        'μg/ml IgG': '2.5',
        rating: 'not elevated',
        category: 'meat',
        food: 'veal',
    },
    {
        'μg/ml IgG': '3.2',
        rating: 'not elevated',
        category: 'meat',
        food: 'venison',
    },
    {
        'μg/ml IgG': '3.3',
        rating: 'not elevated',
        category: 'meat',
        food: 'wild boar',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'mushrooms',
        food: 'meadow mushrooms',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'apple',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'apricot',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'avocado',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'banana',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'cherry',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'date',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'fig',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'grape/raisin',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'kiwi',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'lemon',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'lychee',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'mango',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'nectarine',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'orange',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'papaya',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'peach',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'pear',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'pineapple',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'plum',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'strawberry',
    },
    {
        'μg/ml IgG': '2.6',
        rating: 'not elevated',
        category: 'fruit',
        food: 'watermelon',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'fruit',
        food: 'yellow plum',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'almond',
    },
    {
        'μg/ml IgG': '11.9',
        rating: 'elevated',
        category: 'seeds and nuts',
        food: 'brazil nut',
    },
    {
        'μg/ml IgG': '19.2',
        rating: 'highly elevated',
        category: 'seeds and nuts',
        food: 'cashew kernels',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'cocoa bean',
    },
    {
        'μg/ml IgG': '10.0',
        rating: 'elevated',
        category: 'seeds and nuts',
        food: 'coconut',
    },
    {
        'μg/ml IgG': '8.4',
        rating: 'elevated',
        category: 'seeds and nuts',
        food: 'hazelnut',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'linseed',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'macadamia nut',
    },
    {
        'μg/ml IgG': '99.3',
        rating: 'highly elevated',
        category: 'seeds and nuts',
        food: 'peanut',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'pine nut',
    },
    {
        'μg/ml IgG': '6.1',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'pistachio',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'poppy seeds',
    },
    {
        'μg/ml IgG': '3.8',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'pumpkin seeds',
    },
    {
        'μg/ml IgG': '2.7',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'sesame',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'sunflower seeds',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'seeds and nuts',
        food: 'walnut',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'teas, coffee, and tannin',
        food: 'peppermint',
    },
    {
        'μg/ml IgG': '2.6',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'basil',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'chive',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'cinnamon',
    },
    {
        'μg/ml IgG': '86.1',
        rating: 'highly elevated',
        category: 'spices and herbs',
        food: 'garlic',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'horseradish',
    },
    {
        'μg/ml IgG': '3.9',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'mustard seed',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'nutmeg',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'oregano',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'paprika, spice',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'parsley',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'pepper, black',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'rosemary',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'thyme',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'spices and herbs',
        food: 'vanilla',
    },
    {
        'μg/ml IgG': '3.3',
        rating: 'not elevated',
        category: 'milk products',
        food: "camel's milk",
    },
    {
        'μg/ml IgG': '12.5',
        rating: 'elevated',
        category: 'milk products',
        food: 'goat milk and cheese',
    },
    {
        'μg/ml IgG': '10.6',
        rating: 'elevated',
        category: 'milk products',
        food: 'halloumi',
    },
    {
        'μg/ml IgG': '27.8',
        rating: 'highly elevated',
        category: 'milk products',
        food: 'kefir',
    },
    {
        'μg/ml IgG': '3.7',
        rating: 'not elevated',
        category: 'milk products',
        food: "mare's milk",
    },
    {
        'μg/ml IgG': '57.0',
        rating: 'highly elevated',
        category: 'milk products',
        food: 'milk (cow)',
    },
    {
        'μg/ml IgG': '24.6',
        rating: 'highly elevated',
        category: 'milk products',
        food: 'milk, cooked',
    },
    {
        'μg/ml IgG': '15.8',
        rating: 'elevated',
        category: 'milk products',
        food: 'rennet cheese (cow)',
    },
    {
        'μg/ml IgG': '31.4',
        rating: 'highly elevated',
        category: 'milk products',
        food: 'ricotta',
    },
    {
        'μg/ml IgG': '14.0',
        rating: 'elevated',
        category: 'milk products',
        food: 'sheep: milk and cheese',
    },
    {
        'μg/ml IgG': '43.8',
        rating: 'highly elevated',
        category: 'milk products',
        food: 'sour-milk products (cow)',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'salads',
        food: 'butterhead lettuce',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'salads',
        food: 'chicory',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'salads',
        food: 'endive',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'salads',
        food: 'iceberg lettuce',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'salads',
        food: "lamb's lettuce",
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'salads',
        food: 'lollo rosso',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'salads',
        food: 'radicchio',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'salads',
        food: 'rocket',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'salads',
        food: 'romaine / cos lettuce',
    },
    {
        'μg/ml IgG': '8.0',
        rating: 'elevated',
        category: 'eggs',
        food: 'chicken eggs (white and yolk)',
    },
    {
        'μg/ml IgG': '3.5',
        rating: 'not elevated',
        category: 'eggs',
        food: 'goose eggs',
    },
    {
        'μg/ml IgG': '2.7',
        rating: 'not elevated',
        category: 'eggs',
        food: 'quail eggs',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'food additives',
        food: 'guar flour (E412)',
    },
    {
        'μg/ml IgG': '2.8',
        rating: 'highly elevated',
        category: 'cereals containing gluten',
        food: 'barley',
    },
    {
        'μg/ml IgG': '18.5',
        rating: 'highly elevated',
        category: 'cereals containing gluten',
        food: 'gluten',
    },
    {
        'μg/ml IgG': '10.6',
        rating: 'highly elevated',
        category: 'cereals containing gluten',
        food: 'kamut',
    },
    {
        'μg/ml IgG': '6.7',
        rating: 'highly elevated',
        category: 'cereals containing gluten',
        food: 'oats',
    },
    {
        'μg/ml IgG': '9.4',
        rating: 'highly elevated',
        category: 'cereals containing gluten',
        food: 'rye',
    },
    {
        'μg/ml IgG': '25.4',
        rating: 'highly elevated',
        category: 'cereals containing gluten',
        food: 'spelt',
    },
    {
        'μg/ml IgG': '26.7',
        rating: 'highly elevated',
        category: 'cereals containing gluten',
        food: 'wheat',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'amaranth',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'arrowroot',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'buckwheat',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'carob',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'cassava',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'fonio',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'jerusalem artichoke',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'lupine',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'maize, sweet corn',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'millet',
    },
    {
        'μg/ml IgG': '18.3',
        rating: 'highly elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'quinoa',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'rice',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'sweet chestnut',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'sweet potato',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'tapioca',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'cereals w/o gluten and alternatives',
        food: 'teff',
    },
    {
        'μg/ml IgG': '< 2.5',
        rating: 'not elevated',
        category: 'yeast',
        food: 'yeast',
    },
]
