import { CellClassParams, ColDef } from 'ag-grid-community'
import { Row } from './data-types'

const ratingHighlighter = ({ data }: CellClassParams<Row>) => {
    const rating = data?.rating!

    switch (rating) {
        case 'not elevated':
            return { color: 'white', backgroundColor: 'green' }
        case 'elevated':
            return { color: 'white', backgroundColor: 'orange' }
        case 'highly elevated':
            return { color: 'white', backgroundColor: 'red' }
        default:
            throw new Error('Unhandled case in rating highlighter')
    }
}

export const columnDefs: ColDef[] = [
    { field: 'food', filter: true },
    {
        field: 'rating',
        cellStyle: ratingHighlighter,
        sort: 'asc',
    },
    { field: 'category' },
    {
        field: 'μg/ml IgG',
        comparator: (valueA, valueB) => {
            const isVeryLowRange = (value: string) => value === '< 2.5'
            const lowRangeA = isVeryLowRange(valueA)
            const lowRangeB = isVeryLowRange(valueB)
            if (lowRangeA && lowRangeB) {
                return 0
            }

            if (lowRangeB && !lowRangeA) {
                return 1
            }

            if (lowRangeA && !lowRangeB) {
                return -1
            }

            return Number(valueA) - Number(valueB)
        },
    },
]
