import { useState } from 'react'
import { rowData as initialRowData } from './row-data'
import { columnDefs as initialColumnDefs } from './column-defs'
import { Row } from './data-types'
import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

function App() {
    const [columnDefs] = useState(initialColumnDefs)
    const [rowData] = useState<Row[]>(initialRowData)
    return (
        <div
            className="ag-theme-balham"
            style={{ height: '100vh', width: '100vw' }}
        >
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={{
                    sortable: true,
                }}
            />
        </div>
    )
}

export default App
